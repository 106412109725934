import { useEffect, useState } from 'react'
import getCampaignsForFilter from 'services/getCampaignsForFilter'

export default function useGetCampaignsForFilter(accesToken) {

  const [campaings, setCampaings] = useState([])

  useEffect(() => {
    getCampaignsForFilter(accesToken).then(campaings => setCampaings(campaings))
  }, [accesToken])


  return {
    campaings,
  }
} 