import {URL} from './urls';

export default function getCampaignsForFilter(accesToken) {
  const apiURL = URL + `getCampaignsForFilter`

  return fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => res.json())
    .then(response => {
      const tempArray = [{
        label: `no campaign`,
        value: `no campaign`,
      }];
      if (response) {
          if (response.data.length) {
          response.data[0].forEach((element) => {
            tempArray.push({
              label: `${element.name}`,
              value: element.id,
            });
          });
        } else {
          tempArray.push({
            label: `${response.name}`,
            value: response.id,
          });
        }
      }
      return tempArray
    })
}