import { useEffect, useContext } from "react"
// services
import insertPhones from 'services/insertPhones'
//context - config
import AuthProvider from 'context/AuthProvider'

const ParseAndCheckData = ({ excelData, setError, setParseResult, setShowUploadSpinner }) => {

  const auth = useContext(AuthProvider)

  useEffect(() => {

    // Verifica si excelData está vacío
    if (!excelData || !excelData.length) {
      return
    }
    // Verifica si excelData solo tiene una fila
    if (excelData.length === 1) {
      setError("fichero sin llamadas")
      return
    }

    // chequeo que las columnas esxisten
    if (!excelData[0].includes("Phone")) {
      setError("No existe la columna Phone");
      return;
    }
    if (!excelData[0].includes("User")) {
      setError("No existe la columna User");
      return;
    }

    // Chequea que Phone tenga una longitud de al menos 7 dígitos
    const destinationColumnIndex = excelData[0].indexOf("Phone")
    const invalidDestinationLength = excelData.slice(1).find(
      (rowData) => String(rowData[destinationColumnIndex]).length < 7
    )

    if (invalidDestinationLength) {
      setError("Error en la columna Phone")
      return
    }


    const handleInsert = async () => {

      insertPhones(auth.accesToken, excelData)
        .then(response => {
          if (!response.status) {
            setError(response.message)
          }
          else setParseResult(response.message)
          setShowUploadSpinner(false)
        })
    }
    /* eslint-disable */

    handleInsert()

  }, [excelData])

  return null
}

export default ParseAndCheckData
