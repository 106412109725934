import { useEffect, useContext } from "react";
// services
import insertCalls from "services/insertCalls";
//context - config
import AuthProvider from "context/AuthProvider";

const ParseAndCheckData = ({
  excelData,
  setError,
  setParseResult,
  setShowUploadSpinner,
  getCallsFunction,
}) => {
  const auth = useContext(AuthProvider);

  useEffect(() => {
    // Verifica si excelData está vacío
    if (!excelData || !excelData.length) {
      return;
    }
    // Verifica si excelData solo tiene una fila
    if (excelData.length === 1) {
      setError("fichero sin llamadas");
      return;
    }

    // 2. Chequea que todos los elementos de Date tengan el formato "dd/mm/yyyy"
    const dateColumnIndex = excelData[0].indexOf("Date");
    const invalidDateFormat = excelData
      .slice(1)
      .find(
        (rowData) => !/^\d{2}\/\d{2}\/\d{4}$/.test(rowData[dateColumnIndex])
      );

    if (invalidDateFormat) {
      setError("Error en la columna Date");
      return;
    }

    // 3. Chequea que todos los elementos de Time tengan el formato "hh:mm:ss"
    const timeColumnIndex = excelData[0].indexOf("Time");
    const invalidTimeFormat = excelData
      .slice(1)
      .find((rowData) => !/^\d{2}:\d{2}:\d{2}$/.test(rowData[timeColumnIndex]));

    if (invalidTimeFormat) {
      setError("Error en la columna Time");
      return;
    }

    // 4. Chequea que User y Time sean enteros
    const userColumnIndex = excelData[0].indexOf("User");
    const invalidUserOrTime = excelData
      .slice(1)
      .find(
        (rowData) =>
          !Number.isInteger(parseInt(rowData[userColumnIndex], 10)) ||
          !Number.isInteger(parseInt(rowData[timeColumnIndex], 10))
      );

    if (invalidUserOrTime) {
      setError("Error en las columnas User o Time");
      return;
    }

    // 5. Chequea que Destination tenga una longitud de al menos 7 dígitos
    const destinationColumnIndex = excelData[0].indexOf("Destination");
    const invalidDestinationLength = excelData
      .slice(1)
      .find((rowData) => String(rowData[destinationColumnIndex]).length < 7);

    if (invalidDestinationLength) {
      setError("Error en la columna Destination");
      return;
    }

    // 6. Crea un nuevo array con DateTime en formato "yyyy/mm/dd hh:mm:ss"
    const newDataWithDateTime = excelData.map((rowData, rowIndex) => {
      if (rowIndex === 0) {
        return [...rowData, "DateTime"];
      } else {
        const dateColumnIndex = excelData[0].indexOf("Date");
        const timeColumnIndex = excelData[0].indexOf("Time");

        const dateParts = rowData[dateColumnIndex].split("/");
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

        return [...rowData, `${formattedDate} ${rowData[timeColumnIndex]}`];
      }
    });

    // 7. Elimina las columnas "Date" y "Time" del nuevo array
    const newDataWithoutDateAndTime = newDataWithDateTime.map(
      (rowData, rowIndex) => {
        if (rowIndex === 0) {
          // Crea una nueva fila de encabezados sin las columnas "Date" y "Time"
          const dateColumnIndex = excelData[0].indexOf("Date");
          const timeColumnIndex = excelData[0].indexOf("Time");

          const newHeaderRow = rowData.filter(
            (_, columnIndex) =>
              columnIndex !== dateColumnIndex && columnIndex !== timeColumnIndex
          );
          return newHeaderRow;
        } else {
          // Crea una nueva fila sin las columnas "Date" y "Time"
          const dateColumnIndex = excelData[0].indexOf("Date");
          const timeColumnIndex = excelData[0].indexOf("Time");

          const newRowData = rowData.filter(
            (_, columnIndex) =>
              columnIndex !== dateColumnIndex && columnIndex !== timeColumnIndex
          );
          return newRowData;
        }
      }
    );

    const handleInsert = async () => {
      insertCalls(auth.accesToken, newDataWithoutDateAndTime).then(
        (response) => {
          if (!response.status) {
            setError(response.message);
          } else setParseResult(response.message);
          setTimeout(() => {
            setShowUploadSpinner(false);
            getCallsFunction();
          }, 2000);
        }
      );
    };
    /* eslint-disable */

    handleInsert();
  }, [excelData]);

  return null;
};

export default ParseAndCheckData;
