import { URL } from './urls';

export default function insertCalls(accesToken, newDataWithoutDateAndTime) {

  const apiURL = URL + `calls`
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    },
    body: JSON.stringify({
      data: newDataWithoutDateAndTime,
    })
  };

  return fetch(apiURL, requestOptions)
    .then(res => res.json())
    .then(response => {
      return response
    })
    .catch(() => 'Error en request'
    );

}