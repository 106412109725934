import { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner'
//components
import PhonesTable from 'components/phones/PhonesTable'
import Pagination from 'components/controls/Pagination'
import PhoneSearchBar from 'components/phones/PhoneSearchBar'
import UploadXlsxFile from 'components/phones/UploadXlsxFile'

//context - config
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'
// services
import getPhones from 'services/getPhones'
import blockPhone from "services/blockPhone"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const ShowPhones = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { t } = useContext(TranslationProvider)

  const [phones, setPhones] = useState([])
  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [perPage, setPerPage] = useState(500)
  const [refreshPage, setRefresPage] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  const [showUploadSpinner, setShowUploadSpinner] = useState(false)
  const [textToFind, setTextToFind] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [phoneStatusSelected, setPhoneStatusSelected] = useState("active")

  const [attemptsModalOpen, setAttemptsModalOpen] = useState(false)
  const [selectedPhoneAttempts, setSelectedPhoneAttempts] = useState([])
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('')

  /* eslint-disable */
  useEffect(() => {
    getPhones(auth.accesToken, currrentPage, perPage, textToFind, phoneStatusSelected)
      .then(response => {
        setPhones(response.data)
        setLastPage(response.meta.last_page)
        setPerPage(response.meta.per_page)
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [currrentPage, refreshPage, textToFind, phoneStatusSelected])

  const blockPhoneFunction = async (e) => {
    // Mostrar el spinner
    setShowSpinner(true);

    blockPhone(auth.accesToken, e)
      .then(response => {
        if (!response.status) {
          setErrMsg(response.message)
        }
      })
      .finally(() => {
        // Ocultar el spinner después de la llamada, ya sea éxito o error
      });

    setTimeout(() => {
      setRefresPage((refreshPage) => !refreshPage)
      setShowSpinner(false);

    }, 2000)
  }

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openAttemptsModal = (phone) => {
    setSelectedPhoneAttempts(phone.download_list_attempts)
    setSelectedPhoneNumber(phone.phone)
    setAttemptsModalOpen(true)
  }

  const closeAttemptsModal = () => {
    setAttemptsModalOpen(false)
  }

  const calculateTimeDifference = (current, previous) => {
    if (!previous) return null;
    const currentDate = new Date(current);
    const previousDate = new Date(previous);
    const difference = Math.abs(currentDate - previousDate) / 60000; // difference in minutes
    return difference.toFixed(2);
  }

  return (
    <div style={{ padding: "20px" }}>
      <PhoneSearchBar
        textToFind={textToFind}
        setTextToFind={setTextToFind}
        setCurrrentPage={setCurrrentPage}
        openModal={openModal}
        setPhoneStatusSelected={setPhoneStatusSelected}
        phoneStatusSelected={phoneStatusSelected}
      />
      {showSpinner &&
        <Spinner
          animation="border" variant="primary"
          style={{ "display": "grid", "margin": "auto" }}
        />}

      {isModalOpen && (
        <div className="modalUploadPhone">
          {/* Contenido de la ventana emergente */}
          <div className="modalUploadPhone-content">
            <span className="closeUploadPhone" onClick={closeModal}>
              &times;
            </span>
            <h2>{t('calls.uploadFile')} XLSX</h2>
            {showUploadSpinner &&
              <Spinner
                animation="border" variant="primary"
                style={{ "display": "grid", "margin": "auto" }}
              />}
            <UploadXlsxFile
              auth={auth.accesToken}
              setShowUploadSpinner={setShowUploadSpinner}
            />
          </div>
        </div>
      )}

      <PhonesTable
        phones={phones}
        blockPhoneFunction={blockPhoneFunction}
        openAttemptsModal={openAttemptsModal}
      />

      <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />

      <Modal show={attemptsModalOpen} onHide={closeAttemptsModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedPhoneNumber} - {t('calls.downloadListAttempts')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPhoneAttempts.length > 0 ? (
            <ul>
              {selectedPhoneAttempts.map((attempt, index) => (
                <li key={attempt.id}>
                  {attempt.created_at} 
                  {index < selectedPhoneAttempts.length - 1 && (
                    <span style={{ marginLeft: '10px', color: 'gray' }}>
                      ({calculateTimeDifference(attempt.created_at, selectedPhoneAttempts[index + 1]?.created_at)} {t('minutes')})
                    </span>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('calls.noAttempts')}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAttemptsModal}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ShowPhones
