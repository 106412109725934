import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import TranslationProvider from 'context/TranslationProvider'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"
import { AiFillEdit } from "react-icons/ai"

const UsersTable = (props) => {

  const { t } = useContext(TranslationProvider)

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            <Th>{t('users.name')}</Th>
            <Th>{t('users.phone')}</Th>
            <Th>{t('users.email')}</Th>
            <Th>{t('users.twoFACode')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.users.map((user) => (
            <Tr key={user.id}>
              <Td> {user.name} </Td>
              <Td type="center"> {user.phone} </Td>
              <Td> {user.email} </Td>
              <Td type="center"> {user.twoFACode} </Td>
              <Td>
                <Link to={`/users/edit/${user.id}`}>
                  <AiFillEdit />
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default UsersTable