import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from 'context/TranslationProvider';
import { FormLabel, FormButton2, FormHead, FormError } from "styles/form";
import getPhone from 'services/getPhone';
import updatePhone from 'services/updatePhone';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Schema de validación
const schema = yup.object({
  user_id: yup.string().required(),
  phone: yup.string().required(),
  blocked: yup.number().oneOf([0, 1], 'Blocked must be either 0 or 1').required(),
  utc: yup.string()
    .matches(/^(\+|-)\d+$/, 'UTC must be a valid UTC offset, for example +2')
    .required('UTC is required')
}).required();

const EditPhone = () => {
  const { t } = useContext(TranslationProvider);
  const auth = useContext(AuthProvider);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showSpinner, setShowSpinner] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    getPhone(auth.accesToken, id)
      .then(response => {
        if (!response.status) {
          setErrMsg(response.message);
        }
        setValue('user_id', response.user_id);
        setValue('phone', response.phone);
        setValue('blocked', response.blocked);
        setValue('utc', response.utc);
      })
      .catch(() => {
        navigate('/');
        window.sessionStorage.setItem('loggedNewIntranetAppUser', '');
        window.location.reload();
      });
  }, [id, setValue, auth.accesToken, navigate]);

  const onSubmit = (data) => {
    setShowSpinner(true);
    updatePhone(auth.accesToken, id, data)
      .then(response => {
        if (!response.status) {
          setErrMsg(response.message);
          setShowSpinner(false);
        } else {
          setShowSpinner(false);
          navigate('/phones');
        }
      })
      .catch(() => {
        navigate('/');
        window.sessionStorage.setItem('loggedNewIntranetAppUser', '');
        window.location.reload();
      });
  };

  const goBack = () => {
    navigate('/phones');
  };

  return (
    <div>
      {showSpinner && (
        <Spinner
          animation="border" variant="primary"
          style={{ "display": "grid", "margin": "auto" }}
        />
      )}
      <FormHead>{t('phones.editPhone')}</FormHead>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormLabel>User ID</FormLabel>
          <input
            {...register("user_id")}
            type='text'
            className='form-control'
            disabled
          />
          {errors.user_id && <FormError>{errors.user_id.message}</FormError>}
        </div>
        <div>
          <FormLabel>{t('phones.phone')}</FormLabel>
          <input
            {...register("phone")}
            type='text'
            className='form-control'
            disabled
          />
          {errors.phone && <FormError>{errors.phone.message}</FormError>}
        </div>
        <div>
          <FormLabel>{t('phones.blocked')}</FormLabel>
          <input
            {...register("blocked")}
            type='number'
            className='form-control'
          />
          {errors.blocked && <FormError>{errors.blocked.message}</FormError>}
        </div>
        <div>
          <FormLabel>UTC</FormLabel>
          <input
            {...register("utc")}
            type='text'
            className='form-control'
          />
          {errors.utc && <FormError>{errors.utc.message}</FormError>}
        </div>
        <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>
        <FormButton2 type="submit">{t('update')}</FormButton2>
        <FormButton2 type="button" onClick={goBack}>{t('back')}</FormButton2>
      </form>
    </div>
  );
};

export default EditPhone;
