import * as XLSX from "xlsx"

export default function exportToExcel(calls, downLoadExcelFile, setDownLoadExcelFile) {

  const excelData = calls.map(call => ({
    "Campaña": call.campaign,
    "Fecha/Hora": call.date,
    "Teléfono": call.phone,
    "Destino": call.destination,
    "Duración": parseFloat(call.duration),
    "Estado": call.last_status,
  }))

  if (excelData[0] === undefined) return {}

  const worksheet = XLSX.utils.json_to_sheet(excelData)
  const workbook = XLSX.utils.book_new();

  // Obtén la fecha y hora actual
  var now = new Date();

  // Formatea la fecha y hora
  var formattedDate = now.toISOString().slice(0, 19).replace("T", " ");

  const fileName = 'Llamadas ' + formattedDate 

  XLSX.utils.book_append_sheet(workbook, worksheet, fileName)

  if (excelData && excelData.length && downLoadExcelFile) {
    XLSX.writeFile(workbook, fileName + ".xlsx", { compression: true })
    setDownLoadExcelFile(0)
  }

  return {}
}
