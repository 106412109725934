import React from "react"
import { Routes, Route } from "react-router-dom"

// pages
import ChangePassword from "pages/ChangePassword"
import Home from "pages/Home"
import MovileurosSales from "pages/MovileurosSales"
import Settings from "pages/Settings"
import ShowCalls from "pages/Calls"
import ShowLogins from "pages/Admin/Login"
import ShowUsers from "pages/Admin/User"
import ShowPhones from "pages/Phones"
import ShowCampaigns from "pages/Campaigns"
//components
import EditUser from "pages/Admin/User/EditUser"
import EditPhone from "pages/Phones/EditPhone"


const RenderRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/change_password" element={<ChangePassword />} />
      <Route
        path="/movileuros_sales_page"
        element={<MovileurosSales />}
      />
      <Route path="/calls" element={<ShowCalls />} />
      <Route path="/phones" element={<ShowPhones />} />
      <Route path="/campaigns" element={<ShowCampaigns />} />
      <Route path="/admin/logins" element={<ShowLogins />} />
      <Route path="/admin/users" element={<ShowUsers />} />
      <Route path="/users/edit/:id" element={<EditUser />} />
      <Route path="/phones/edit/:id" element={<EditPhone />} />
    </Routes>
  )
}

export default RenderRoutes
