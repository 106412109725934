
import React, { useContext } from 'react'

import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import TranslationProvider from 'context/TranslationProvider'

const SearchUserFilter = (props) => {

  const { t } = useContext(TranslationProvider)

  return (
    <Col md="auto">
      <Form.Control
        onChange={(e) => {
          props.setCurrrentPage(1)
          props.setTextToFind(e.target.value)
        }}
        placeholder={t('search_user')}
        aria-label="Username"
        aria-describedby="basic-addon1"
        value={props.textToFind}
      />
    </Col>
  )
}

export default SearchUserFilter