import { URL } from './urls';

//export default function changePassword({ accesToken, pwd, newPwd }) {
  export default function changePassword(accesToken, pwd, newPwd) {

  const apiURL = URL + `changePassword`
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accesToken}` },
    body: JSON.stringify({
      password: pwd,
      new_password: newPwd,
    })
  };

  return fetch(apiURL, requestOptions)
    .then(res => res.json())
    .then(response => {
      return response
    })
    .catch(() => 'Error en request'
    );

}