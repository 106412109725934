import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { AiFillFileExcel, AiOutlineReload } from "react-icons/ai";

//components
import CallStatusFilter from "components/controls/CallStatusFilter";
import PerPageFilter from "components/controls/PerPageFilter";
import CallsTable from "components/calls/CallsTable";
import UploadXlsxFile from "components/calls/UploadXlsxFile";
import Pagination from "components/controls/Pagination";
import SearchUserFilter from "components/controls/SearchBar/SearchUserFilter";
import SinceUntilDatePicker from "components/controls/SinceUntilDatePicker";
import exportToExcel from "components/calls/exportToExcel";
import CallDetailsModal from "components/calls/CallDetailsModal"; // Import the new component

// hooks
import useSinceUntilDatePicker from "hooks/useSinceUntilDatePicker";
import useGetCampaignsForFilter from "hooks/useGetCampaignsForFilter";
//context - config
import AuthProvider from "context/AuthProvider";
import {
  callsInitialStartDate,
  callsInitialEndDate,
} from "config/sinceUntilDatePicker";
import TranslationProvider from "context/TranslationProvider";
// services
import getCalls from "services/getCalls";
import getCallsForExcel from "services/getCallsForExcel";
// React-Toastify
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ShowCalls = () => {
  const { t } = useContext(TranslationProvider);
  const auth = useContext(AuthProvider);
  const navigate = useNavigate();
  const { campaings } = useGetCampaignsForFilter(auth.accesToken);

  const { startDate, endDate, changeStartDate, changeEndDate } =
    useSinceUntilDatePicker({
      initialStartDate: callsInitialStartDate,
      initialEndDate: callsInitialEndDate,
    });

  const [callStatusSelected, setCallStatusSelected] = useState({ value: 'all', label: 'all' });
  const [calls, setCalls] = useState([]);
  const [currrentPage, setCurrrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [perPage, setPerPage] = useState(
    auth.perPage !== undefined ? auth.perPage : 500
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);
  const [textToFind, setTextToFind] = useState("");
  const [campaignSelected, setCampaignSelectedted] = useState("all campaigns");
  const [downLoadExcelFile, setDownLoadExcelFile] = useState(0);
  const [callsForExcel, setCallsForExcel] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);

  const [selectedCall, setSelectedCall] = useState(null); // State to hold the selected call details
  const [showCallDetailsModal, setShowCallDetailsModal] = useState(false); // State to handle modal visibility

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getCallsFunction = () => {
    getCalls(
      auth.accesToken,
      currrentPage,
      perPage,
      textToFind,
      callStatusSelected.value,
      campaignSelected,
      startDate.toISOString(),
      endDate.toISOString()
    )
      .then((response) => {
        setCalls(response.data);
        setLastPage(response.meta.last_page);
        setPerPage(response.meta.per_page);
      })
      .catch(() => {
        navigate("/");
        window.sessionStorage.setItem("loggedNewIntranetAppUser", "");
        window.location.reload();
      });
  };

  const notify = () => {
    toast("Refreshing page!");
  };

  /* eslint-disable */
  useEffect(() => {
    getCallsFunction();
  }, [
    currrentPage,
    perPage,
    textToFind,
    callStatusSelected,
    campaignSelected,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (refreshPage) {
      getCallsFunction();
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    //if (downLoadExcelFile)
    getCallsForExcel(
      auth.accesToken,
      textToFind,
      callStatusSelected.value,
      campaignSelected,
      startDate.toISOString(),
      endDate.toISOString()
    )
      .then((response) => {
        setCallsForExcel(response.data);
      })
      .catch(() => {
        navigate("/");
        window.sessionStorage.setItem("loggedNewIntranetAppUser", "");
        window.location.reload();
      });
    exportToExcel(callsForExcel, downLoadExcelFile, setDownLoadExcelFile);
  }, [
    auth,
    downLoadExcelFile,
    textToFind,
    callStatusSelected,
    campaignSelected,
    startDate,
    endDate,
  ]);

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage);
  };

  const clearPage = () => {
    setTextToFind("");
    setCampaignSelectedted("all campaigns");
    setCurrrentPage(1);
    setCallStatusSelected({ value: 'all', label: 'all' });
    changeStartDate({ startDate: callsInitialStartDate });
    changeEndDate({ endDate: callsInitialEndDate });
  };

  const handleCampaignChange = (e) => {
    setCurrrentPage(1);
    setCampaignSelectedted({
      label: e.label,
      value: e.value,
    });
  };

  const manageExportToExcelCommissions = () => {
    setDownLoadExcelFile(1);
  };

  const handleEyeClick = (call) => {
    setSelectedCall(call);
    setShowCallDetailsModal(true);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row className="justify-content-center text-center mb-3">
        <Col md="auto">
          <SinceUntilDatePicker
            setCurrrentPage={setCurrrentPage}
            startDate={startDate}
            changeStartDate={changeStartDate}
            endDate={endDate}
            changeEndDate={changeEndDate}
          />
        </Col>
        <Col md="auto">
          <Select
            options={campaings}
            onChange={(e) => handleCampaignChange(e)}
            placeholder={campaignSelected}
            value={campaignSelected}
          />
        </Col>
        <Col md="auto">
          <CallStatusFilter
            setCurrrentPage={setCurrrentPage}
            callStatusSelected={callStatusSelected}
            setCallStatusSelected={setCallStatusSelected}
          />
        </Col>
        <Col md="auto">
          <SearchUserFilter
            setCurrrentPage={setCurrrentPage}
            textToFind={textToFind}
            setTextToFind={setTextToFind}
          />
        </Col>

        <Col md="auto">
          <PerPageFilter
            setCurrrentPage={setCurrrentPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Col>

        <Col md="auto">
          <Button type="submit" variant="primary" onClick={clearPage}>
            {t("clean")}
          </Button>
        </Col>

        <Col md="auto">
          <Button type="submit" variant="primary" onClick={openModal}>
            {t("calls.uploadFile")}
          </Button>
        </Col>

        <Col md="auto">
          <AiFillFileExcel
            style={{ color: "#007bff", cursor: "pointer" }}
            size={41}
            onClick={manageExportToExcelCommissions}
          />
        </Col>
        <Col md="auto">
          <AiOutlineReload
            style={{ color: "#007bff", marginTop: 7, cursor: "pointer" }}
            size={25}
            onClick={() => {
              setRefreshPage(true)
              notify()
            }}
          />
        </Col>
      </Row>

      {isModalOpen && (
        <div className="modalUploadPhone">
          {/* Contenido de la ventana emergente */}
          <div className="modalUploadPhone-content">
            <span className="closeUploadPhone" onClick={closeModal}>
              &times;
            </span>
            <h2>{t("calls.uploadFile")} XLSX</h2>
            {showUploadSpinner && (
              <Spinner
                animation="border"
                variant="primary"
                style={{ display: "grid", margin: "auto" }}
              />
            )}
            <UploadXlsxFile
              auth={auth.accesToken}
              setShowUploadSpinner={setShowUploadSpinner}
              getCallsFunction={getCallsFunction}
            />
          </div>
        </div>
      )}
      <CallsTable calls={calls} onEyeClick={handleEyeClick} />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
      <ToastContainer autoClose={200} />

      <CallDetailsModal
        show={showCallDetailsModal}
        handleClose={() => setShowCallDetailsModal(false)}
        call={selectedCall}
      />
    </div>
  );
};

export default ShowCalls;
