import { URL } from './urls';

export default function getCalls(accesToken, currrentPage, perPage, textToFind, callStatusSelected, campaignSelected, startDate, endDate) {

  let campaign = (campaignSelected === 'all campaigns') ? 'all campaigns' : campaignSelected.value

  const apiURL = URL + `calls?page=${currrentPage}&per_page=${perPage}&textToFind=${textToFind}
  &callStatusSelected=${callStatusSelected}&campaignSelected=${campaign}
  &startDate=${startDate}&endDate=${endDate}`

  return fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response
    })
}