import { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"

//components
import LoginsTable from 'components/admin/logins/LoginsTable'
import Pagination from 'components/controls/Pagination'
//context - config
import AuthProvider from 'context/AuthProvider'
// services
import getLogins from 'services/getLogins'

const ShowLogins = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [logins, setLogins] = useState([])

  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  /* eslint-disable */
  useEffect(() => {
    getLogins(auth.accesToken, currrentPage, perPage)
      .then(response => {
        setLogins(response.data[0])
        setLastPage(response.meta.last_page)
        setPerPage(response.meta.per_page)
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [currrentPage])

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  return (
    <div style={{ padding: "20px" }}>
      <LoginsTable
        logins={logins}
      />
      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
      {/* <CallsSearchBar
        agentToFind={agentToFind}
        setAgentToFind={setAgentToFind}
        textToFind={textToFind}
        setTextToFind={setTextToFind}
        setCurrrentPage={setCurrrentPage}
      />
     */}
    </div>
  )
}

export default ShowLogins