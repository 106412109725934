import React, { useState, useEffect } from "react"
import * as XLSX from "xlsx"
import "styles/UploadFileModal.css"
import ParseAndCheckData from "./ParseAndCheckData"
const allowedExtensions = ["xlsx"]

const UploadXlsxFile = ({ setShowUploadSpinner, getCallsFunction }) => {
	const [error, setError] = useState("")
	const [parseResult, setParseResult] = useState("")
	const [file, setFile] = useState("")
	const [excelData, setExcelData] = useState([])

	useEffect(() => {
	}, [excelData])

	const handleFileChange = (e) => {
		setError("")

		if (e.target.files.length) {
			const inputFile = e.target.files[0]

			const fileExtension = inputFile?.name.split(".").pop().toLowerCase()
			if (!allowedExtensions.includes(fileExtension)) {
				setError("Please input an xlsx file")
				return
			}

			setFile(inputFile)
		}
	}

	const handleParse = () => {
		if (!file) return alert("Enter a valid file")

		setShowUploadSpinner(true)
		
		const reader = new FileReader()

		reader.onload = async ({ target }) => {
			const workbook = XLSX.read(target.result, { type: "binary" })

			// Assuming there is only one sheet in the workbook
			const sheetName = workbook.SheetNames[0]
			const sheet = workbook.Sheets[sheetName]

			const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true })

			// Convert numeric date values to formatted strings only for the first two columns
			const formattedData = parsedData.map((row, rowIndex) =>
				row.map((cell, colIndex) =>
					colIndex === 0
						? XLSX.SSF.format("dd/mm/yyyy", cell)
						: colIndex === 1
							? XLSX.SSF.format("hh:mm:ss", cell)
							: cell
				)
			)

			const tableData = formattedData.filter((rowData) =>
				rowData.some((value) => value !== "")
			)

			setExcelData(tableData)
			setFile(null)
		}

		reader.readAsBinaryString(file)
	}

	return (
		<div className="App">
			<div className="containerUploadPhone">
				<input onChange={handleFileChange} id="xlsxInput" name="file" type="File" />
				<div>
					<button onClick={handleParse}>Parse</button>
				</div>
				{error && <div style={{ marginTop: "3rem", color: "red" }}>{error ? error : null}</div>}
				{parseResult && <div style={{ marginTop: "3rem" }}>{parseResult ? parseResult : null}</div>}
			</div>
			<ParseAndCheckData
				excelData={excelData}
				setError={setError}
				setParseResult={setParseResult}
				setShowUploadSpinner={setShowUploadSpinner}
				getCallsFunction={getCallsFunction}
			/>
		</div>
	)
}

export default UploadXlsxFile
