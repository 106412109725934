import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CallDetailsModal = ({ show, handleClose, call }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Call Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {call && call.statuses && call.statuses.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Date Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {call.statuses.map(status => (
                <tr key={status.id}>
                  <td>{new Date(status.created_at).toLocaleString()}</td>
                  <td>{status.status.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No statuses available for this call.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CallDetailsModal;
