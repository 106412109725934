import React, { useContext, useState } from "react"
import Table from "react-bootstrap/Table"
import {
  AiFillDelete,
  AiFillPauseCircle,
  AiFillCaretRight,
} from "react-icons/ai"
import TranslationProvider from "context/TranslationProvider"
import { Tr, Td, Thead, Th, Tbody } from "styles/table"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

const CampaignsTable = (props) => {
  const { t } = useContext(TranslationProvider)

  const [show, setShow] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [modalText, setModaltext] = useState("")
  const [idToDeleteOrUpdate, setIdToDeleteOrUpdate] = useState(0)

  const handleClose = () => setShow(false)
  const handleCloseModalPause = () => setShowPause(false)

  const showModalDelete = (e) => {
    setModaltext(t("campaigns.deleteCampaignText") + e.name)
    setShow(true)
    setIdToDeleteOrUpdate(e.id)
  }

  const showModalPause = (e) => {
    setModaltext(t("campaigns.pauseCampaignText") + e.name)
    setShowPause(true)
    setIdToDeleteOrUpdate(e.id)
  }

  const showModalPlay = (e) => {
    setModaltext(t("campaigns.playCampaignText") + e.name)
    setShowPlay(true)
    setIdToDeleteOrUpdate(e.id)
  }

  const handleDelete = (e) => {
    setShow(false)
    props.deleteCampaignFunction(idToDeleteOrUpdate)
  }

  const handlePause = (e) => {
    setShowPause(false)
    props.updateCampaignFunction(idToDeleteOrUpdate, "pausing")
  }

  const handlePlay = (e) => {
    setShowPlay(false)
    props.updateCampaignFunction(idToDeleteOrUpdate, "playing")
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body> {modalText} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={(e) => handleDelete(e)}>
            {t("campaigns.delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPause} onHide={handleCloseModalPause}>
        <Modal.Body> {modalText} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalPause}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={(e) => handlePause(e)}>
            {t("campaigns.continue")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPlay} onHide={handleCloseModalPause}>
        <Modal.Body> {modalText} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalPause}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={(e) => handlePlay(e)}>
            {t("campaigns.continue")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>{t("campaigns.campaign")}</Th>
            <Th>{t("campaigns.deleted")}</Th>
            <Th>{t("campaigns.paused")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.campaigns.map((campaign) => (
            <Tr key={campaign.id}>
              <Td> {campaign.name} </Td>
              <Td type="center"> {campaign.deleted} </Td>
              <Td type="center"> {campaign.paused} </Td>
              {!campaign.deleted && (
                <Td>
                  <AiFillDelete
                    onClick={(e) => showModalDelete(campaign)}
                    style={{ marginLeft: "10px", color: "#007bff" }}
                  />
                </Td>
              )}
              {!campaign.paused && (
                <Td>
                  <AiFillPauseCircle
                    onClick={(e) => showModalPause(campaign)}
                    style={{ marginLeft: "10px", color: "#007bff" }}
                  />
                </Td>
              )}
              {!!campaign.paused && (
                <Td>
                  <AiFillCaretRight
                    onClick={(e) => showModalPlay(campaign)}
                    style={{ marginLeft: "10px", color: "#007bff" }}
                  />
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default CampaignsTable
