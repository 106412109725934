export const bps = {
  greaterThanMobile: '@media screen and (min-width: 55rem)'
}

export const theme = {
  colors: {
    textColor: 'var(--theme-body-txt)',
    primary: 'var(--brand-color_3)'
  },
  fontSizes: {

  },
  paddings: {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '32px'
  }
}