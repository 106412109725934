import { URL } from './urls';

  export default function blockPhone(accesToken, id) {

  const apiURL = URL + `phones/${id}`
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accesToken}` },
  };

  return fetch(apiURL, requestOptions)
    .then(res => res.json())
    .then(response => {
      return response
    })
    .catch(() => 'Error en request'
    );

}