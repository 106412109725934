
import React, { useContext } from 'react'

import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TranslationProvider from '../../context/TranslationProvider'

const CampaignSearchBar = (props) => {

  const { t } = useContext(TranslationProvider)

  const clearPage = () => {
    props.setTextToFind('')
    props.setCurrrentPage(1)
  }

  return (
    <div style={{ padding: "20px" }}>
      <Container>
        <Row className="justify-content-md-center mb-3">

          <Col md="auto">
            <Form.Control
              onChange={(e) => {
                props.setCurrrentPage(1)
                props.setTextToFind(e.target.value)
              }}
              placeholder={t('campaigns.searchCampaign')}
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={props.textToFind}
            />
          </Col>
          <Col md="auto">
            <Button
              type="submit"
              variant="primary"
              onClick={clearPage}
            >
              {t('clean')}
            </Button>
          </Col>
          <Col md="auto">
            <Button
              type="submit"
              variant="primary"
              onClick={props.openModal}
            >
              {t('calls.uploadFile')}
            </Button>
          </Col>

        </Row>
      </Container>
    </div>
  )
}

export default CampaignSearchBar