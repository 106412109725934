import { useState, useEffect } from 'react'

import Login from "components/auth_control/Login"
import TwoFactorAuth from "components/auth_control/TwoFactorAuth"
import AuthenticatedUser from "components/auth_control/AuthenticatedUser"
import { useTranslation } from 'react-i18next'
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'

function App() {

  const { t, i18n } = useTranslation()

  const [loginParameters, setLoginParameters] = useState({
    roles: [],
    accesToken: '',
    userName: '',
    userSurname: '',
    language: '',
    company: '',
    isNeeded2FA: 0,
    perPage: 0,
    is2FAValidated: 0,
  })

  useEffect(() => {
    const storedUser = window.sessionStorage.getItem('loggedNewIntranetAppUser')
    if (!(typeof window === "undefined") && storedUser && storedUser !== '') {
      const user = JSON.parse(storedUser)
      setLoginParameters(user)
    }
  }, [])

  if (!loginParameters.accesToken) return (
    <Login
      setLoginParameters={(e) => setLoginParameters({
        ...loginParameters,
        isNeeded2FA: e.isNeeded2FA,
        userName: e.userName,
        userSurname: e.userSurname,
        company: e.company,
        language: e.language,
        perPage: e.perPage,
        roles: e.roles,
        accesToken: e.accesToken
      })}
    />
  )
  else if (loginParameters.accesToken && loginParameters.isNeeded2FA && !loginParameters.is2FAValidated) {

    return (
      <TranslationProvider.Provider value={
        {
          t: t,
          i18n: i18n,
        }
      }>
        <AuthProvider.Provider value={loginParameters}>
          <TwoFactorAuth
            setLoginParameters={(e) => setLoginParameters({
              ...loginParameters,
              is2FAValidated: e.is2FAValidated,
            })}
            loginParameters={loginParameters}
          />
        </AuthProvider.Provider>
      </TranslationProvider.Provider>
    )
  }
  else {
    return (
      <TranslationProvider.Provider value={
        {
          t: t,
          i18n: i18n,
        }
      }>
        <AuthProvider.Provider value={loginParameters}>
          <AuthenticatedUser />
        </AuthProvider.Provider>
      </TranslationProvider.Provider>
    )
  }
}

export default App
