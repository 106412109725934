import { URL } from './urls';

export default function updatePhone(accesToken, id, formParameters) {
  const apiURL = URL + `phones/${id}`;

  return fetch(apiURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    },
    body: JSON.stringify(formParameters)
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response;
    });
}
