import { useState, useEffect, useContext } from "react"
import { Form, FormInput, FormButton } from "styles/form"

import { Send2FACode, AddIpToWhiteListService } from "services/apiRequests"
import { apiIntranet } from "services/axios"
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
import { useForm } from "react-hook-form"

const CHECK_2FA_URL = "check2FA"

const TwoFactorAuth = (props) => {
  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)
  const [tfaCode, setTfaCode] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [addIpToWhiteList, setAddIpToWhiteList] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const [apiResponse, setApiResponse] = useState({
    status: null,
    message: "",
  })

  useEffect(() => {
    setErrMsg("")
  }, [tfaCode])

  const handleApiResponse = (e) =>
    setApiResponse({
      ...apiResponse,
      status: e.status,
      message: e.message,
    })

  /* eslint-disable */
  useEffect(() => {
    Send2FACode({
      loginParameters: props.loginParameters,
      fetchApiResponse: handleApiResponse,
      apiResponse: apiResponse,
    })
  }, [])
  /* eslint-disable */

  const onSubmit = async (e) => {
    //e.preventDefault()
    try {
      const response = await apiIntranet.post(
        CHECK_2FA_URL,
        { code: tfaCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.loginParameters.accesToken}`,
          },
        }
      )
      if (response?.data?.status) {
        props.setLoginParameters({
          is2FAValidated: 1,
        })
        if (addIpToWhiteList) {
          AddIpToWhiteListService({ loginParameters: auth })
        }
      } else {
        setErrMsg(response?.data?.message)
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response")
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password")
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized")
      } else {
        setErrMsg("Login Failed")
      }
    }
  }

  const handleCheckBoxChange = () => {
    setAddIpToWhiteList(!addIpToWhiteList)
  }

  // Registra el campo y aplica la validación de longitud de 6 dígitos
  useEffect(() => {
    register("tfaCode", {
      pattern: {
        value: /^[0-9]{6}$/,
        message: t("two_factor_auth.error_message"),
      },
    })
  }, [register])

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="mb-3 fs-3 fw-normal">
          {t("two_factor_auth.main_message")}
        </h1>
        <input
          type="text"
          size="lg"
          placeholder={t("two_factor_auth.place_holder")}
          autoComplete="off"
          className="position-relative"
          onChange={(e) => {
            setTfaCode(e.target.value)
            setValue("tfaCode", e.target.value, { shouldValidate: true })
          }}
        />
        <label>
          <FormInput
            type="checkbox"
            value={addIpToWhiteList}
            onChange={handleCheckBoxChange}
          />{" "}
          {t("two_factor_auth.checkbox_message")}
        </label>

        {errors.tfaCode && (
          <h5 style={{ color: "red" }}>{errors.tfaCode.message}</h5>
        )}
        <h5 style={{ color: "red" }}>{errMsg}</h5>
        {!apiResponse.status && (
          <h5 style={{ color: "red" }}>{apiResponse.message}</h5>
        )}

        <div className="d-grid">
          <FormButton>{t("submit")}</FormButton>
        </div>
      </Form>
    </div>
  )
}

export default TwoFactorAuth
