import { apiIntranet } from "./axios"

const SEND2FA_CODE_URL = "send2FACode"
const ADD_IP_TO_WHITELIST_URL = "addIpToWhiteList"

const Send2FACode = async (props) => {
  if (props.apiResponse.status != null) return

  try {
    const response = await apiIntranet.post(
      SEND2FA_CODE_URL,
      JSON.stringify(props.twofaCode),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.loginParameters.accesToken}`,
        },
      }
    )
    props.fetchApiResponse({
      status: response?.data?.status,
      message: response?.data?.message,
    })
  } catch (err) {
    props.fetchApiResponse({
      status: false,
      message: err,
    })
  }
}

const AddIpToWhiteListService = async (props) => {
  try {
    const response = await apiIntranet.post(
      ADD_IP_TO_WHITELIST_URL,
      JSON.stringify("mando esto para que funcione"),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.loginParameters.accesToken}`,
        },
      }
    )
    props.fetchApiResponse({
      status: response?.data?.status,
      message: response?.data?.message,
    })
  } catch (err) {
    props.fetchApiResponse({
      status: false,
      message: err,
    })
  }
}

export { Send2FACode, AddIpToWhiteListService }
