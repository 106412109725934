import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import TranslationProvider from 'context/TranslationProvider'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"
import { AiFillEye } from "react-icons/ai";

const CallsTable = (props) => {

  const { t } = useContext(TranslationProvider)

  const getTdStyle = (webStatusId) => {
    return webStatusId === 21 ? { backgroundColor: '#ffcccc' } : {};
  }

  const calculateDelay = (call) => {
    const activeStatus = call.statuses.find(status => status.status_id === 4);
    if (!activeStatus) return null;

    const callDate = new Date(call.date);
    const activeStatusDate = new Date(activeStatus.created_at);

    const delayMs = activeStatusDate - callDate;
    const delayMinutes = Math.floor((delayMs % 3600000) / 60000);
    const delaySeconds = Math.floor((delayMs % 60000) / 1000);

    return `${delayMinutes}:${delaySeconds < 10 ? '0' : ''}${delaySeconds}`;
  }

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            <Th>{t('campaigns.campaign')}</Th>
            <Th>{t('calls.dateTime')}</Th>
            <Th>{t('calls.duration')}</Th>
            <Th>{t('calls.until')}</Th> {/* Nueva columna */}
            <Th>{t('calls.delay')}</Th> {/* Nueva columna */}
            {/* <Th>{t('calls.phone')}</Th> */}
            <Th>{t('calls.user')}</Th>
            <Th>{t('calls.destination')}</Th>
            <Th>{t('calls.last_status')}</Th>
            <Th>{t('actions')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.calls.map((call) => (
            <Tr key={call.id}>
              <Td style={getTdStyle(call.web_status_id)}> {call.campaign} </Td>
              <Td style={getTdStyle(call.web_status_id)} type="center"> {call.date} </Td>
              <Td style={getTdStyle(call.web_status_id)} type="number"> {call.duration} </Td>
              <Td style={getTdStyle(call.web_status_id)} type="center">
                {new Date(Date.parse(call.date) + call.duration * 1000).toLocaleString().slice(-8)} {/* Calcula y muestra la fecha hasta */}
              </Td>
              <Td style={getTdStyle(call.web_status_id)} type="center"> {calculateDelay(call)} </Td>
              {/* <Td style={getTdStyle(call.web_status_id)} type="center"> {call.phone} </Td> */}
              <Td style={getTdStyle(call.web_status_id)} type="center"> {call.user} </Td>
              <Td style={getTdStyle(call.web_status_id)} type="center"> {call.destination} </Td>
              <Td style={getTdStyle(call.web_status_id)} type="center"> {call.last_status} </Td>
              <Td style={getTdStyle(call.web_status_id)} type="center">
                <AiFillEye
                  style={{ "marginLeft": "10px", "color": "#007bff", "cursor": "pointer" }}
                  onClick={() => props.onEyeClick(call)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default CallsTable
