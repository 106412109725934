import { URL } from './urls';

export default function getPhones(accesToken, currrentPage, perPage, textToFind, phoneStatusSelected) {
  const apiURL = URL + `phones?page=${currrentPage}&per_page=${perPage}&textToFind=${textToFind}&phoneStatusSelected=${phoneStatusSelected}`

  return fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response
    })
}