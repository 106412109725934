import { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import Spinner from "react-bootstrap/Spinner"
//components
import CampaignsTable from "components/campaigns/CampaignsTable"
import Pagination from "components/controls/Pagination"
import CampaignSearchBar from "components/campaigns/CampaignSearchBar"

//context - config
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
// services
import getCampaigns from "services/getCampaigns"
import deleteCampaign from "services/deleteCampaign"
import updateCampaign from "services/updateCampaign"

const ShowCampaigns = () => {
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { t } = useContext(TranslationProvider)

  const [campaigns, setCampaigns] = useState([])
  const [currrentPage, setCurrrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [refreshPage, setRefresPage] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)
  const [showUploadSpinner, setShowUploadSpinner] = useState(false)
  const [textToFind, setTextToFind] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)

  /* eslint-disable */
  useEffect(() => {
    getCampaigns(auth.accesToken, currrentPage, perPage, textToFind)
      .then((response) => {
        setCampaigns(response.data[0])
        setLastPage(response.meta.last_page)
        setPerPage(response.meta.per_page)
      })
      .catch(() => {
        navigate("/")
        window.sessionStorage.setItem("loggedNewIntranetAppUser", "")
        window.location.reload()
      })
  }, [currrentPage, refreshPage, textToFind])
  
  const deleteCampaignFunction = async (e) => {
    // Mostrar el spinner
    setShowSpinner(true)

    deleteCampaign(auth.accesToken, e)
      .then((response) => {
        if (!response.status) {
          setErrMsg(response.message)
        }
      })
      .finally(() => {
        // Ocultar el spinner después de la llamada, ya sea éxito o error
      })

    setTimeout(() => {
      setRefresPage((refreshPage) => !refreshPage)
      setShowSpinner(false)
    }, 2000)
  }

  const updateCampaignFunction = async (id, action) => {
    // Mostrar el spinner
    setShowSpinner(true)

    updateCampaign(auth.accesToken, id, action)
      .then((response) => {
        if (!response.status) {
          setErrMsg(response.message)
        }
      })
      .finally(() => {
        // Ocultar el spinner después de la llamada, ya sea éxito o error
      })

    setTimeout(() => {
      setRefresPage((refreshPage) => !refreshPage)
      setShowSpinner(false)
    }, 2000)
  }

  const paginate = (currrentPage) => {
    setCurrrentPage(currrentPage)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div style={{ padding: "20px" }}>
      <CampaignSearchBar
        textToFind={textToFind}
        setTextToFind={setTextToFind}
        setCurrrentPage={setCurrrentPage}
        openModal={openModal}
      />
      {showSpinner && (
        <Spinner
          animation="border"
          variant="primary"
          style={{ display: "grid", margin: "auto" }}
        />
      )}

      {isModalOpen && (
        <div className="modalUploadPhone">
          {/* Contenido de la ventana emergente */}
          <div className="modalUploadPhone-content">
            <span className="closeUploadPhone" onClick={closeModal}>
              &times
            </span>
            <h2>{t("calls.uploadFile")} XLSX</h2>
            {showUploadSpinner && (
              <Spinner
                animation="border"
                variant="primary"
                style={{ display: "grid", margin: "auto" }}
              />
            )}
          </div>
        </div>
      )}

      <CampaignsTable
        campaigns={campaigns}
        deleteCampaignFunction={deleteCampaignFunction}
        updateCampaignFunction={updateCampaignFunction}
      />

      <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </h4>

      <Pagination
        lastPage={lastPage}
        currrentPage={currrentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default ShowCampaigns
