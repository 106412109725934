import { URL } from './urls';

export default function updateUser(accesToken, id, formParameters) {
  const apiURL = URL + `users/${id}`

  return fetch(apiURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${accesToken}`
    },
    body: JSON.stringify({
      id: formParameters.id,
      name: formParameters.name,
      phone: formParameters.phone,
      email: formParameters.email,
      perPage: (formParameters.perPage.label === undefined) ? formParameters.perPage : formParameters.perPage.label,
      language: (formParameters.language.label === undefined) ? formParameters.language : formParameters.language.label,
      twoFACode: formParameters.twoFACode,
    })
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response
    })
}
