import React from 'react';
import Select from "react-select";

const CallStatusFilter = ({ callStatusSelected, setCallStatusSelected, setCurrrentPage }) => {
  const handlePerPageChange = (e) => {
    setCurrrentPage(1);
    setCallStatusSelected(e);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '15ch', // Set the minimum width to 15 characters
    }),
  };

  return (
    <Select
      options={[
        { value: 'all', label: 'all' },
        { value: 4, label: 'active' },
        { value: 7, label: 'completed by system' },
        { value: 6, label: 'loaded' },
        { value: 3, label: 'pending' },
        { value: 1, label: 'ringing' },
        { value: 14, label: 'successful' },
        { value: 9, label: 'connecting' },
      ]}
      onChange={handlePerPageChange}
      value={callStatusSelected}
      styles={customStyles}
    />
  );
};

export default CallStatusFilter;
