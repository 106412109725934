import React, { useContext, useState } from 'react'
import Table from 'react-bootstrap/Table'
import TranslationProvider from 'context/TranslationProvider'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import { AiFillEdit, AiFillCloseCircle, AiFillEye } from "react-icons/ai";

const PhonesTable = (props) => {

  const { t } = useContext(TranslationProvider)

  const [show, setShow] = useState(false)
  const [modalText, setModaltext] = useState("")
  const [idToDelete, setIdToDelete] = useState(0)
  const handleClose = () => setShow(false)

  const showModalDelete = (e) => {
    setModaltext(t('calls.blockPhoneText') + e.phone)
    setShow(true)
    setIdToDelete(e.id)
  }
  const handleDelete = (e) => {
    setShow(false)
    props.blockPhoneFunction(idToDelete)
  }

  const handleViewAttempts = (phone) => {
    props.openAttemptsModal(phone)
  }

  const isStale = (lastListUpdate) => {
    if (!lastListUpdate) return true;
    
    const oneHourAgo = new Date().getTime() - 3600000; // Hace una hora en milisegundos
    const lastUpdateTime = new Date(lastListUpdate).getTime();

    return lastUpdateTime < oneHourAgo;
  }

  const getTdStyle = (lastListUpdate) => {
    return isStale(lastListUpdate) ? { backgroundColor: '#ffcccc' } : {};
  }  

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body> {modalText} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={(e) => handleDelete(e)}>
            {t('calls.block')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>User</Th>
            <Th>{t('calls.phone')}</Th>
            <Th>{t('calls.blocked')}</Th>
            <Th>UTC</Th>
            <Th>{t('calls.last_list_update')}</Th>
            <Th>{t('calls.last_call_date')}</Th>
            <Th>{t('actions')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.phones.map((phone) => (
            <Tr key={phone.id}>
              <Td style={getTdStyle(phone.last_list_update)}> {phone.user_id} </Td>
              <Td style={getTdStyle(phone.last_list_update)}> {phone.phone} </Td>
              <Td style={getTdStyle(phone.last_list_update)} type="center"> {phone.blocked} </Td>
              <Td style={getTdStyle(phone.last_list_update)} type="center"> {phone.utc} </Td>
              <Td style={getTdStyle(phone.last_list_update)} type="center"> {phone.last_list_update} </Td>
              <Td style={getTdStyle(phone.last_list_update)} type="center"> {phone.last_call_date} </Td>
              <Td style={getTdStyle(phone.last_list_update)} type="center">
                {!phone.blocked &&
                  <AiFillCloseCircle
                    onClick={(e) => showModalDelete(phone)}
                    style={{ "color": "#007bff" }}
                  />
                }
                <AiFillEye
                  style={{ "marginLeft": "10px", "color": "#007bff", "cursor": "pointer" }}
                  onClick={() => handleViewAttempts(phone)}
                />
                <Link to={`/phones/edit/${phone.id}`}>
                  <AiFillEdit style={{ "marginLeft": "10px", "color": "#007bff", "cursor": "pointer" }} />
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default PhonesTable
