import { URL } from './urls'

export default function updateCampaign(accesToken, id, action) {
  const apiURL = URL + `campaings/${id}`

  return fetch(apiURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${accesToken}`
    },
    body: JSON.stringify({
      id: id,
      action: action,
     })
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!")
      }
      return res.json()
    })
    .then(response => {
      return response
    })
}
