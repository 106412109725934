import React from 'react'
import Select from "react-select"

const PhoneStatusFilter = ({ phoneStatusSelected, setPhoneStatusSelected, setCurrrentPage }) => {

  const handlePerPageChange = (e) => {
    setCurrrentPage(1)
    setPhoneStatusSelected(e.value)
  }

  return (
    <Select
      options={[
        { value: 'all', label: 'all' },
        { value: 'active', label: 'active' },
        { value: 'blocked', label: 'blocked' },
      ]}
      onChange={(e) => handlePerPageChange(e)}
      value={{ value: phoneStatusSelected, label: phoneStatusSelected }}
    />

  );
};

export default PhoneStatusFilter