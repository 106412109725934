import React, { useEffect, useState, useContext } from 'react';
import Container from 'react-bootstrap/Container'
import { useNavigate, useParams } from 'react-router-dom';
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from 'context/TranslationProvider'
import { FormLabel, FormButton2, FormHead } from "styles/form"
import getUser from 'services/getUser'
import updateUser from 'services/updateUser'
import Spinner from 'react-bootstrap/Spinner'
import Select from "react-select";

const EditUser = () => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { id } = useParams()
  const [formParameters, setFormParameters] = useState({
    name: '',
    phone: '',
    email: '',
    language: [],
    perPage: [],
    twoFACode: ''
  })
  const [errMsg, setErrMsg] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const update = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    updateUser(auth.accesToken, id, formParameters)
      .then(response => {
        if (!response.status) {
          setErrMsg(response.message)
        }
      })
    .catch(() => {
      navigate('/')
      window.sessionStorage.setItem(
        'loggedNewIntranetAppUser', ''
      )
      window.location.reload()
    })

    setTimeout(() => {
      setShowSpinner(false);
      navigate('/admin/users')
    }, 2000)

  }

  const goBack = async (e) => {
    e.preventDefault();
    navigate('/admin/users')
  }

  /* eslint-disable */
  useEffect(() => {
    getUser(auth.accesToken, id)
      .then(response => {
        if (!response.status) {
          setErrMsg(response.message)
        }
        setFormParameters({
          ...formParameters,
          id: response.id,
          name: response.name,
          phone: response.phone,
          email: response.email,
          language: {
            label: response.language ? response.language : null,
            value: response.language ? response.language : null
          },
          perPage: {
            label: response.per_page ? response.per_page : null,
            value: response.per_page ? response.per_page : null
          },
          twoFACode: response.isNeeded2FA,
        })
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [])

  // I have to wait for the perPage to load before randerizing
  if (formParameters.perPage.length === 0 || formParameters.language.length === 0) return;

  return (
    <Container>
      {showSpinner &&
        <Spinner
          animation="border" variant="primary"
          style={{ "display": "grid", "margin": "auto" }}
        />}

      <FormHead>{t('users.userEdit')}</FormHead>
      <form>
        <div>
          <FormLabel>{t('users.name')}</FormLabel>
          <input
            value={formParameters.name}
            onChange={(e) => setFormParameters({
              ...formParameters,
              name: e.target.value
            })}
            type='text'
            className='form-control'
          />
        </div>
        <div>
          <FormLabel>{t('users.phone')}</FormLabel>
          <input
            value={formParameters.phone}
            onChange={(e) => setFormParameters({
              ...formParameters,
              phone: e.target.value
            })}
            type='text'
            className='form-control'
          />
        </div>
        <div>
          <FormLabel>{t('users.email')}</FormLabel>
          <input
            value={formParameters.email}
            onChange={(selectedOption) => setFormParameters({
              ...formParameters,
              email: selectedOption ? selectedOption.value : null,
            })}
            type='text'
            className='form-control'
          />
        </div>
        <div>
          <FormLabel>{t('users.language')}</FormLabel>
          <Select
            options={[
              { value: 'en', label: 'en' },
              { value: 'es', label: 'es' },
            ]}
            onChange={(selectedOption) => setFormParameters({
              ...formParameters,
              language: selectedOption ? selectedOption.value : null,
            })}
            defaultValue={formParameters.language}
          />
        </div>
        <div>
          <FormLabel>{t('users.perPage')}</FormLabel>
          <Select
            options={[
              { value: 5, label: 5 },
              { value: 10, label: 10 },
              { value: 20, label: 20 },
              { value: 50, label: 50 },
              { value: 100, label: 100 },
              { value: 500, label: 500 },
            ]}
            onChange={(e) => setFormParameters({
              ...formParameters,
              perPage: e.value
            })}
            defaultValue={formParameters.perPage}
          />
        </div>
        <div>
          <FormLabel>{t('users.twoFACode')}</FormLabel>
          <input
            value={formParameters.twoFACode}
            onChange={(e) => setFormParameters({
              ...formParameters,
              twoFACode: e.target.value
            })}
            type='number'
            className='form-control'
          />
        </div>
        <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

        <FormButton2 onClick={update}>{t('update')}</FormButton2>
        <FormButton2 onClick={goBack}>{t('back')}</FormButton2>
      </form>
    </Container>
  );
};
export default EditUser;