import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import TranslationProvider from 'context/TranslationProvider'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const LoginsTable = (props) => {

  const { t } = useContext(TranslationProvider)

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            <Th>{t('logins.user')}</Th>
            <Th>{t('logins.ip')}</Th>
            <Th>{t('logins.dateTime')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.logins.map((login) => (
            <Tr key={login.id}>
              <Td> {login.userName} </Td>
              <Td type="center"> {login.ipv4} </Td>
              <Td type="center"> {login.created_at} </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default LoginsTable